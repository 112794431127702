import { TimelineMax, Power1 } from "gsap/TweenMax";

/**
 *
 *  Handles the transition from slide three to two
 */
const threeTwo = () => {
  const slideTwo = document.querySelector(".-slide-2");
  const slideThree = document.querySelector(".-slide-3");
  const slideTwoContent = document.querySelector(".-slide-2 .l-slide-content");
  const cardOverlays = document.querySelectorAll(
    ".card-image .slide-background-color"
  );

  const tween = new TimelineMax();
  tween.to(slideTwo, 0, { opacity: 1 });
  tween.to(slideThree, 1.2, { xPercent: "100", ease: Power1.easeInOut });
  tween.to(slideTwo, 1.2, { xPercent: "0", x: "0", ease: Power1.easeInOut }, "-=1.2");
  tween.to(
    cardOverlays,
    0.5,
    {
      opacity: 1
    },
    "-=0.5"
  );
  tween.to(
    slideTwoContent,
    0.5,
    { x: "0", xPercent: "-50%", left: "50%", overwrite: true },
    "-=1"
  );
};

export default threeTwo;
