import oneTwo from "./oneTwo";
import oneThree from "./oneThree";
import twoThree from "./twoThree";
import twoOne from "./twoOne";
import threeTwo from "./threeTwo";
import threeOne from "./threeOne";

export default {
  oneTwo,
  oneThree,
  twoThree,
  twoOne,
  threeTwo,
  threeOne
};
