import { TimelineMax, Power1 } from "gsap/TweenMax";

/**
 *
 *  Handles the transition from slide one to three
 */
const oneThree = () => {
  const slideOne = document.querySelector(".-slide-1");
  const slideTwo = document.querySelector(".-slide-2");
  const slideTwoContent = document.querySelector(".-slide-2 .l-slide-content");
  const slideThree = document.querySelector(".-slide-3");
  const cards = document.querySelectorAll(".card");
  const cardOverlays = document.querySelectorAll(
    ".card-image .slide-background-color"
  );

  const tween = new TimelineMax();
  tween.to(slideTwo, 0, { opacity: 0 });
  tween.to(slideOne, 1.2, { xPercent: "-100", ease: Power1.easeInOut });
  tween.to(slideTwo, 1.2, { x: "0", xPercent: "-100", ease: Power1.easeInOut  }, "-=1.2");
  tween.to(slideThree, 1.2, { xPercent: "0", x: "0", ease: Power1.easeInOut  }, "-=1.2");
  tween.staggerFrom(cards, 1, { opacity: 0, y: "50px", ease: Power1.easeInOut }, 0.3, "-=0.5");
  tween.to(
    cardOverlays,
    0.85,
    {
      opacity: 0,
      ease: Power1.easeInOut
    },
    "-=1"
  );
  tween.to(slideTwo, 0.1, { opacity: 1 });
};

export default oneThree;
