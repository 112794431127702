import TweenMax from "gsap/TweenMax";

const darkenControls = () => {
  const numberControls = document.querySelectorAll(".number-controls button");
  TweenMax.to(numberControls, 1.5, {
    "background-color": "transparent",
    color: "#000000"
  });
};

const lightenControls = () => {
  const numberControls = document.querySelectorAll(".number-controls button");
  TweenMax.to(numberControls, 1.5, {
    "background-color": "rgba(0,0,0,0.8)",
    color: "#ffffff"
  });
};

export { darkenControls, lightenControls };
