import load from "./load";
import {
  darkenControls,
  lightenControls,
} from "./controls";

export default {
  load,
  darkenControls,
  lightenControls,
};
