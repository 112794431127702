import { TimelineMax, Power1 } from "gsap/TweenMax";

/**
 *
 *  Handles the transition from slide two to one
 */
const twoOne = () => {
  const slideOne = document.querySelector(".-slide-1");
  const slideTwo = document.querySelector(".-slide-2");
  const tween = new TimelineMax();
  tween.to(slideOne, 1.2, { xPercent: "0", x: "0", ease: Power1.easeInOut });
  tween.to(slideTwo, 1.2, { x: "0", xPercent: "100", ease: Power1.easeInOut }, "-=1.2");
};

export default twoOne;
