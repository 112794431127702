import { TimelineMax, Power1 } from "gsap/TweenMax";

/**
 *
 *  Handles the transition from slide one to two
 */
const oneTwo = () => {
  const slideOne = document.querySelector(".-slide-1");
  const slideTwo = document.querySelector(".-slide-2");
  const slideTwoContent = document.querySelector(".-slide-2 .l-slide-content");
  const tween = new TimelineMax();
  tween.to(slideOne, 1.2, { xPercent: "-100", ease: Power1.easeInOut });
  tween.to(slideTwo, 1.2, { xPercent: "0", x: "0", ease: Power1.easeInOut }, "-=1.2");
  tween.to(
    slideTwoContent,
    0.5,
    { x: "-50%", left: "50%", overwrite: true },
    "-=1"
  );
};

export default oneTwo;
