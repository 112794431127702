import TweenMax, { Power2 } from 'gsap/TweenMax';

const hideNavControl = direction => {
  const el = document.querySelector(`.nav-controls .-${direction}`);
  el.style.cssText = 'pointer-events: none';
  TweenMax.to(el, 1, { opacity: 0 });
};

const load = () => {
  const el = document.querySelector('.l-navigation');
  TweenMax.to(el, 1, {
    bottom: 0,
    opacity: 1,
    ease: Power2.easeInOut
  });
};

export default load;
