import { TimelineMax, Power1 } from "gsap/TweenMax";

/**
 *
 *  Handles the transition from slide three to one
 */
const threeTwo = () => {
  const slideThree = document.querySelector(".-slide-3");
  const slideOne = document.querySelector(".-slide-1");
  const slideTwo = document.querySelector(".-slide-2");
  const cardOverlays = document.querySelectorAll(
    ".card-image .slide-background-color"
  );

  const tween = new TimelineMax();
  tween.to(slideThree, 1.2, { xPercent: "100", ease: Power1.easeInOut });
  tween.to(slideOne, 1.2, { x: "0", xPercent: "0", ease: Power1.easeInOut}, "-=1.2");
  tween.to(slideTwo, 0, { xPercent: "100", x: "0" });
  tween.to(
    cardOverlays,
    0.5,
    {
      opacity: 1
    },
    "-=0.5"
  );
};

export default threeTwo;
