import '@stimulus/polyfills';
import "core-js/web/dom-collections";
import "core-js/es/symbol";
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import VideoPlayer from '@padillaco/video-player';

const application = Application.start();
const context = require.context('./controllers', true, /\.js$/);
application.load(definitionsFromContext(context));
new VideoPlayer();
