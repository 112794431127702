import { TweenMax, TimelineMax, Power2 } from 'gsap/TweenMax';

// How often we should poll the video for its currentTime property,
// in milliseconds and seconds
const pollingInterval = 75;

// the length for each FadeIn and FadeOut transition in seconds
const fadeDuration = 0.3;

const exploreButtonDelay = 2;
const exploreButtonDuration = 1;

// Timestamps in seconds for each heading transition trigger.
const headingTransitionTimes = {
  sceneOne: {
    out: 4.263
  },
  sceneTwo: {
    in: 4.813,
    out: 8.476
  },
  sceneThree: {
    in: 9.026,
    out: 11.854
  },
  sceneFour: {
    in: 12.404
  }
};

const headingText = [
  {
    top: 'How can science',
    bottom: 'and wholesomeness coexist?'
  },
  {
    top: 'How are healthier animals',
    bottom: 'connected to healthier people?'
  },
  {
    top: 'Can abundance',
    bottom: 'ever be sustainable?'
  },
  {
    top: 'Cargill is collaborating with farmers',
    bottom: 'to address the most essential questions in agriculture.'
  }
];

/**
 * Creates and returns an interval that polls the video progress, triggering
 * heading transitions at the appropriate times.
 * @param {element} container
 * @param {element} video
 * @param {function} cb
 * @returns setInterval Id
 */
const headings = (container, video, cb = () => {}) => {
  var currentIndex = 0;
  const headings = container.querySelector('h1');
  const [topHeading, bottomHeading] = headings.querySelectorAll('span');

  return setInterval(function updateVideoTime() {
    if (shouldTransitionHeadings()) {
      currentIndex++;
      transitionHeading();
    }
  }, pollingInterval);

  /**
   * Transitions the headings from current index to taret index
   * @param {Integrer} index target transition
   */
  function transitionHeading() {
    const tl = new TimelineMax();
    const delay = getDelayForTransition(currentIndex);

    tl.to(headings, fadeDuration, {
      opacity: 0,
      ease: Power2.easeInOut,
      onComplete: () => {
        topHeading.innerHTML = headingText[currentIndex].top;
        bottomHeading.innerHTML = headingText[currentIndex].bottom;
        if (currentIndex == 3) {
          cb();
        }
      }
    }).to(headings, fadeDuration, {
      opacity: 1,
      ease: Power2.easeInOut,
      delay: delay
    });
  }

  /**
   * Determines whether or not a heading transition should occur
   * @return {boolean}
   */
  function shouldTransitionHeadings() {
    const { sceneOne, sceneTwo, sceneThree } = headingTransitionTimes;
    const { currentTime } = video;
    switch (currentIndex) {
      case 0:
        return betweenInterval(currentTime, sceneOne.out);
      case 1:
        return betweenInterval(currentTime, sceneTwo.out);
      case 2:
        return betweenInterval(currentTime, sceneThree.out);
    }
  }

  /**
   * Returns the delay between when the last heading fades out and the new
   * heading fades in.
   * @param {index} index target scene
   * @return delay value
   */
  function getDelayForTransition(index) {
    const {
      sceneOne,
      sceneTwo,
      sceneThree,
      sceneFour
    } = headingTransitionTimes;
    switch (index) {
      case 1:
        return sceneTwo.in - sceneOne.out;
      case 2:
        return sceneThree.in - sceneTwo.out;
      case 3:
        return sceneFour.in - sceneThree.out;
      default:
        return 0;
    }
  }

  /**
   * returns true if now is between startTime and startTime plus the
   * pollingInterval;
   * @param {number} now
   * @param {number} startTime
   * @return {boolean}
   */
  function betweenInterval(now, startTime) {
    return now > startTime && now < startTime + pollingInterval / 1000;
  }
};

export default headings;
