import { Controller } from 'stimulus';
import { navigation, transitions, headings } from '../animations';

/**
 *
 *  Main event controller for the application:
 *   - Handles all click events to trigger slide transitions
 *   - Runs initial animations for slide one Headings and navigation
 *   - Sets appropriate active classes for number controls
 *   - Opens/Closes video modal
 *
 *  Visit sitimulusjs.org for Controller classs documentation
 */
export default class extends Controller {
  static targets = [
    'numberButtons',
    'modal',
    'iframe',
    'video',
    'mobileVideo',
    'slideOne',
    'slideOneMobile'
  ];

  videoInterval = null;
  mobileVideoInterval = null;

  /**
   * called when the controller is connected to the DOM
   * see: https://stimulusjs.org/reference/lifecycle-callbacks#connection
   */
  connect() {
    this.numberButtonsTargets[0].classList.add('-button-active');
    this.videoTarget.addEventListener('play', this.initSlideOne.bind(this));
    this.mobileVideoTarget.addEventListener(
      'play',
      this.initSlideOneMobile.bind(this)
    );
    this.videoTarget.play();
    this.mobileVideoTarget.play();
  }

  /**
   * called when the controller is disconnected to the DOM
   * see: https://stimulusjs.org/reference/lifecycle-callbacks#disconnection
   */
  disconnect() {
    this.videoTarget.removeEventListener('play', this.initSlideOne);
    this.mobileVideoTarget.removeEventListener('play', this.initSlideOneMobile);
    clearInterval(this.videoInterval);
    clearInterval(this.mobileVideoInterval);
  }

  initSlideOne() {
    navigation.load();
    this.videoInterval = headings(this.slideOneTarget, this.videoTarget);
  }

  initSlideOneMobile() {
    this.mobileVideoInterval = headings(
      this.slideOneMobileTarget,
      this.mobileVideoTarget
    );
  }

  nextSlide() {
    const currIndex = parseInt(this.data.get('index'));
    const nextIndex = this.incrementSlideindex(currIndex);
    this.updateActiveNumberControl(nextIndex);
    switch (currIndex) {
      case 1:
        transitions.oneTwo();
        navigation.darkenControls();
        break;
      case 2:
        transitions.twoThree();
        break;
    }
  }

  previousSlide() {
    const currIndex = parseInt(this.data.get('index'));
    const nextIndex = this.decrementSlideindex(currIndex);
    this.updateActiveNumberControl(nextIndex);

    switch (currIndex) {
      case 2:
        transitions.twoOne();
        navigation.lightenControls();
        break;
      case 3:
        transitions.threeTwo();
    }
    this.decrementSlideindex(currIndex);
  }

  updateActiveNumberControl(nextIndex) {
    this.numberButtonsTargets.map((v, i) => {
      if (nextIndex !== i + 1) {
        v.classList.remove('-button-active');
      } else {
        v.classList.add('-button-active');
      }
    });
  }

  transitonViaNumberControls(e) {
    const currIndex = parseInt(this.data.get('index'));
    const nextIndex = this.setSlideIndexFromNumberControl(e);

    this.updateActiveNumberControl(nextIndex);

    if (currIndex === 1 && nextIndex === 2) {
      transitions.oneTwo();
      navigation.darkenControls();
    }
    if (currIndex === 1 && nextIndex === 3) {
      transitions.oneThree();
      navigation.darkenControls();
    }
    if (currIndex === 2 && nextIndex === 1) {
      transitions.twoOne();
      navigation.lightenControls();
    }
    if (currIndex === 2 && nextIndex === 3) {
      transitions.twoThree();
    }
    if (currIndex === 3 && nextIndex === 1) {
      transitions.threeOne();
      navigation.lightenControls();
    }
    if (currIndex === 3 && nextIndex === 2) {
      transitions.threeTwo();
    }
  }

  setSlideIndexFromNumberControl(e) {
    const nextIndex = parseInt(e.currentTarget.dataset.index);
    this.data.set('index', nextIndex);
    return nextIndex;
  }

  incrementSlideindex(currIndex) {
    const nextIndex = currIndex === 3 ? 3 : currIndex + 1;
    this.data.set('index', nextIndex);
    return nextIndex;
  }

  decrementSlideindex(currIndex) {
    const nextIndex = currIndex === 1 ? 1 : currIndex - 1;
    this.data.set('index', nextIndex);
    return nextIndex;
  }

  openModalWindow(e) {
    const url = e.target.getAttribute('data-video-url');
    this.iframeTarget.setAttribute('src', url);
    this.modalTargets.forEach(el => {
      el.classList.add('-visible');
    });
  }

  closeModalWindow() {
    this.modalTargets.forEach(el => {
      el.classList.remove('-visible');
    });
    this.iframeTarget.setAttribute('src', '');
  }
}
